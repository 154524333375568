/* eslint-disable no-useless-escape */
import { nanoid } from 'nanoid';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import UserFacingParamComponent from '../components/UserFacingParamComponent';
import { Task, TaskExecutionState } from '../types/task';
import axios from 'axios';
import getURLParameter from '../getURLParameter';
import '../index.css';
import { StorageUtility } from '../StorageUtility';

const syntaxHighlight = (json: string): string => {
  if (typeof json !== 'string') {
    json = JSON.stringify(json, undefined, 2);
  }
  json = json.replace(/&/g, '&amp;').replace(/</g, '&lt;').replace(/>/g, '&gt;');

  return json.replace(
    /("(\\u[a-zA-Z0-9]{4}|\\[^u]|[^\\"])*"(\s*:)?|\b(true|false|null)\b|-?\d+(?:\.\d*)?(?:[eE][+\-]?\d+)?)/g,
    (match) => {
      let cls = 'json-number';
      if (/^"/.test(match)) {
        if (/:$/.test(match)) {
          cls = 'json-key';
        } else {
          cls = 'json-string';
        }
      } else if (/true|false/.test(match)) {
        cls = 'json-boolean';
      } else if (/null/.test(match)) {
        cls = 'json-null';
      }
      const copyValue = match.replace(/^"|"$/g, '');
      return `<span class="${cls}" data-copy="${copyValue}">${match}</span>`;
    }
  );
};

const copyToClipboard = (text: string): void => {
  navigator.clipboard.writeText(text);
};

function StyledJSON(data: { value: string }) {
  const formattedJson = syntaxHighlight(data.value);
  const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
    const target = event.target as HTMLElement;

    const textToCopy = target.getAttribute('data-copy');
    if (textToCopy) {
      copyToClipboard(textToCopy);
    }
  };
  return (
    <div
      className="  "
      style={{
        whiteSpace: 'pre-wrap',
        wordWrap: 'break-word'
      }}
      onClick={handleClick}
      dangerouslySetInnerHTML={{ __html: formattedJson }}></div>
  );
}

const TaskDetailsPage = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [taskExecutionStatus, setTaskExecutionStatus] = useState<TaskExecutionState>();
  const [slugValues, setSlugValues] = useState<Map<string, string>>(new Map());
  const [task, setTask] = useState<Task>();
  const reactNavigator = useHistory();
  const taskId = getURLParameter('id');
  const navigator = useHistory();
  const handleBackClick = () => {
    reactNavigator.goBack();
  };

  function replaceSlugIfAny(jsonString: string) {
    if (jsonString) {
      slugValues.forEach((value, key) => {
        const placeholder = `{{${key}}}`;
        jsonString = jsonString?.replace(placeholder, value);
      });
      return jsonString;
    }
    return jsonString;
  }

  const convertNumericStringsToNumbers = (payload: Record<string, any>): Record<string, any> => {
    for (const key in payload) {
      if (typeof payload[key] === 'string' && !isNaN(Number(payload[key]))) {
        payload[key] = Number(payload[key]);
      }
    }
    return payload;
  };

  const addExecutionState = (description: string, style: string) => {
    const state: TaskExecutionState = {
      id: nanoid(),
      stateDescription: description,
      style: style
    };

    setTaskExecutionStatus(state);
  };

  const handleResponse = (response: any) => {
    setLoading(false);
    addExecutionState(JSON.stringify(response.data, null, 2), 'text-semibold text-light-orange');
  };

  const handleError = (error: any) => {
    console.log(error);
    setLoading(false);
    if (error.response && error.response.data) {
      const parsedError = {
        httpStatus: error.response.data.httpStatus,
        timestamp: error.response.data.timestamp,
        errorCode: error.response.data.errorCode,
        errorMessage: error.response.data.errorMessage,
        debugMessage: error.response.data.debugMessage,
        responseCode: error.response.data.responseCode,
        responseMessage: error.response.data.responseMessage,
        data: error.response.data.data
      };
      addExecutionState(JSON.stringify(parsedError, null, 2), 'text-semibold text-red');
    } else {
      addExecutionState(JSON.stringify(error, null, 2), 'text-semibold text-red');
    }
  };

  const executeTask = () => {
    if (!task) return;
    console.log(setSlugValues);
    const mappedEndpointUrl = replaceSlugIfAny(`${task.endpointBaseUrl}${task.endpoint}`);
    console.log(mappedEndpointUrl);

    const mappedJson = replaceSlugIfAny(JSON.stringify(task.endpointJSONBody));
    const updatedMappedJson = JSON.stringify(
      convertNumericStringsToNumbers(JSON.parse(mappedJson))
    );
    let header = {};

    if (task.urlHeaders) {
      const mappedHeaders = replaceSlugIfAny(JSON.stringify(task.urlHeaders));
      header = JSON.parse(mappedHeaders as any);
    }
    const methodType = task.endpointMethodType;
    if (methodType == 'get') {
      axios
        .get(mappedEndpointUrl, { params: { updatedMappedJson } })
        .then(function (response) {
          handleResponse(response);
        })
        .catch(function (error) {
          handleError(error);
        });
    } else if (methodType == 'post') {
      axios
        .post(mappedEndpointUrl, JSON.parse(updatedMappedJson))
        .then(function (response) {
          handleResponse(response);
        })
        .catch(function (error) {
          console.log(error.response);
          handleError(error);
        });
    } else if (methodType == 'patch') {
      axios
        .patch(mappedEndpointUrl, updatedMappedJson, {
          headers: {
            'Content-Type': 'application/json'
          }
        })
        .then(function (response) {
          handleResponse(response);
        })
        .catch(function (error) {
          handleError(error);
        });
    }
  };

  async function getTaskById(taskId: string) {
    const response = await axios
      .get('/los/v3/api-playground/task/' + taskId)
      .then(function (response) {
        const task: Task = response.data;
        setTask(task);
      })
      .catch(function (error) {
        console.log(error);
        if (error.response) {
          if (
            error.response.status === 401 ||
            error.response.status === 403 ||
            error.response?.data?.errorCode === 'VGP_TOKEN_1000'
          ) {
            console.log('Unauthorized access - 401 error. Logging out');
            StorageUtility.clear();
            navigator.replace('/login');
          } else {
            console.log('Error:', error.response.status, error.response.data);
          }
        } else {
          console.log('Error:', error.message);
        }
      });
  }

  const deleteTask = () => {
    if (!task) return;
    axios
      .delete(`/los/v3/api-playground/task/${task.id}`)
      .then(function (response: any) {
        console.log(response);
        reactNavigator.goBack();
      })
      .catch(function (error: any) {
        console.log(error);
      });
  };

  const updateParamValue = (slug: string, updatedValue: any) => {
    updateSlugValue(slug, updatedValue);
  };

  const updateSlugValue = (key: string, value: any) => {
    setSlugValues((prevSlugValues) => {
      const newSlugValues = new Map(prevSlugValues);
      newSlugValues.set(key, value);
      return newSlugValues;
    });
  };

  useEffect(() => {
    const abortController = new AbortController();

    if (taskId) {
      getTaskById(taskId);
    }

    return () => abortController.abort();
  }, [taskId]);

  useEffect(() => {
    if (task) {
      task.userFacingParams?.forEach((i) => {
        updateSlugValue(i.slug, i.defaultParamValue);
      });
    }
  }, [task]);

  return (
    <div className="flex flex-row justify-between h-full">
      <div className=" flex-1 ">
        <div className="flex flex-col gap-2 items-left">
          <div className="flex flex-row justify-between items-center">
            <label className="text-bold text-5xl">{task?.name}</label>
            <button
              className="rounded bg-white border border-red text-red px-2 py-1 hover:bg-opacity-40 hover:bg-red hover:text-white hover:border-opacity-40"
              onClick={deleteTask}>
              Delete Task
            </button>
          </div>
          <label>{task?.description}</label>
        </div>

        <div className="flex flex-row " style={{ width: '95vw' }}>
          <div
            className="flex-1 rounded-md w-full shadow-sm overflow-scroll overflow-x-hidden "
            style={{
              height: 'calc(90vh - 20px)'
            }}>
            <div className="mt-10 p-4">
              {task?.userFacingParams?.map((param) => (
                <UserFacingParamComponent
                  param={param}
                  key={param._id}
                  onValueChange={(slug, updatedValue) => updateParamValue(slug, updatedValue)}
                  mapWithParamValue={slugValues}
                />
              ))}
            </div>
            <div
              className="mt-12 flex flex-row items-center justify-between rounded-md"
              style={{
                position: 'sticky',
                bottom: '0px',
                backgroundColor: 'white',
                padding: '1rem',
                width: '!important 100%'
              }}>
              <button
                className="px-4 rounded border-2 border-solid border-primary-light text-primary-dark hover:bg-opacity-20 hover:bg-primary-dark hover:border-opacity-20"
                onClick={handleBackClick}>
                Back
              </button>
              <button
                className="bg-primary-light px-4 py-1 rounded hover:bg-primary-dark shadow-md text-white"
                onClick={executeTask}>
                Execute
              </button>
            </div>
          </div>
          <div
            className="flex flex-col gap-2 rounded-md flex-1  overflow-scroll h-screen p-4"
            style={{ height: 'calc(90vh - 20px)' }}>
            <label className="text-2xl">Output</label>
            <div className="rounded-md  w-full h-full p-4 shadow-sm ">
              {taskExecutionStatus && (
                <div key={taskExecutionStatus.id}>
                  <StyledJSON value={taskExecutionStatus.stateDescription}></StyledJSON>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TaskDetailsPage;
